import axios from "axios";



const apiUrl = "https://descours-cabaud-bv3.obea-ecoute-engagement.fr/1.0/";

function buildUrl(url) {
    return apiUrl + url;
}

export default {
    post(path, payload) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .post(url, payload)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    },
    get(path) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => error);
    },
    put(path, payload) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .post(url, payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => error);
    },
    delete(path) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .delete(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => error);
    },
};
